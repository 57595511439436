<template>
  <div
    v-if="dataOptions.input"
    ref="formWrapper"
    class="counter-proposal input-component">
    <v-card>
      <v-container>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation>
          <v-layout>
            <v-number-field
              required
              currency
              not-replace
              label="Valor"
              class="proposal-value"
              @keypress.enter="save"
              @change="amountValue" />
            <v-text-field
              v-model.number="refuse.parcel"
              required
              label="Parcelas"
              class="mx-3"
              type="number"
              mask="##"
              :rules="numberRules"
              @keypress.enter="save" />
            <v-btn
              v-if="isMobile"
              :disabled="!valid"
              color="accent"
              class="btn-mobile-save"
              data-cy="btnSave"
              icon
              @click.native="save">
              <v-icon small>
                mdi-send
              </v-icon>
            </v-btn>
            <v-btn
              v-else
              :disabled="!valid"
              color="accent"
              class="btn-big"
              data-cy="btnSave"
              @click.native="save">
              Enviar
            </v-btn>
            <v-btn
              v-if="isMobile"
              color="gray"
              icon
              dark
              class="btn-mobile-prev"
              title="Selecionar outro motivo"
              data-cy="btnPrev"
              @click.native="prev">
              <v-icon small>
                arrow_back_ios
              </v-icon>
            </v-btn>
            <v-btn
              v-else
              color=""
              class="btn-big"
              title="Selecionar outro motivo"
              data-cy="btnPrev"
              @click.native="prev">
              Outro motivo
            </v-btn>
          </v-layout>
        </v-form>
      </v-container>
    </v-card>
  </div>
  <message
    v-else
    :data="data" />
</template>

<script>
  import Message from './Message'
  import chatUiMixin from '@/mixins/chat-ui'
  import validate from '@/mixins/validate'

  export default {
    components: { Message },
    mixins: [chatUiMixin, validate],
    data () {
      return {
        refuse: {
          amount: '',
          parcel: 1
        },
        valid: true
      }
    },
    computed: {
      dataOptions () {
        return this.data
      }
    },
    mounted () {
      this.startAnimation()
      this.$nextTick(() => {
        document.querySelector('.proposal-value input') !== null && this.focus()
      })
    },
    methods: {
      save (ev) {
        this.endAnimation()
        ev.preventDefault()
        if (this.$refs.form.validate()) {
          let callback = () => {
            let option = {
              id: this.data.id,
              value: this.refuse
            }
            this.$emit('send', option)
          }
          this.endAnimation(callback)
        }
      },
      focus () {
        document.querySelector('.proposal-value input').focus()
      },
      prev () {
        let callback = () => {
          let option = {
            id: this.data.id,
            value: 'prev'
          }
          this.$emit('send', option)
        }
        this.endAnimation(callback)
      },
      amountValue (val) {
        this.refuse.amount = val.discount.toFixed(2)
      }
    }
  }
</script>

<style lang="sass">
  .counter-proposal

    &-value
      flex-grow: 2

    .container
      max-width: 600px

    .btn-mobile-prev i
      margin-left: 5px

    .btn-mobile-save
      margin-right: 0
      margin-left: 0

    .btn-mobile-prev
      margin-right: 0
</style>
